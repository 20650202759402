export function isOpeningIsThePreferToEnter() {
  return localStorage.getItem('size_entering_type') === 'Opening'
}

export function isDropIsTheSizeToEnter() {
  return localStorage.getItem('prefer_entries') === 'drop'
}

export function firstDimensionPreference() {
  return localStorage.getItem('prefer_entries')
}

export function sizeEnteringPreference() {
  return localStorage.getItem('size_entering_type')
}
