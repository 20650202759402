<template>
  <div class="enter-size">
    <div class="enter-size__container tw-gap-3">
      <div class="enter-size__left">
        <div class="enter-size__mode tw-gap-3 tw-pb-5" v-if="!isQuoting">
          Size entering mode:
          <RadioGroupItems class="enter-size__panel" :items="modes" :disabled="isCustomLayout" v-model="sizeData.mode" />

        </div>
        <div class="enter-size__title" v-if="showSameDifferentSize">
          Opening panels are:
          <RadioGroupItems class="enter-size__panel" :items="isAllSectionsSameSizeOptions"
             :value="sizeData.is_all_sections_same_size"
            @update:modelValue="sizeData.is_all_sections_same_size = $event" />
        </div>
        <div class="tw-max-w-sm" v-if="!isQuoting && sizeData.mode === 'calc'">
          Layout configuration
          <SelectDropdown :list="availableDeductionSetsForDropDown" :listIsObject="true" :small="true"
            placeholder="Select" @select="applyLayoutFormula($event)" :selected="selectedSet" type="id">
          </SelectDropdown>
        </div>

        <div class="enter-size__example" :class="getSectionsExampleClass()" v-if="showLayoutIcon && layoutData">
          <Line class="example__line_width" text="Overall Width" />
          <Line class="example__line_height" text="Overall&nbsp;Drop" :vertical="true" />

          <svg-icon-qouting
            :data='layoutData || [[{ "col": 1, "row": 1, "image": sectionsData[0].icon || "layout-icon-window-screen-casement-window-1z.svg", "width": 1, "height": 1, "number": "1", "default_formula_drop": "overall_drop / 1", "default_formula_width": "overall_width / 1" }]]'
            :key="`section-example-${index}`" />
        </div>
        <div v-else class="enter-size__example" :class="getSectionsExampleClass()">

          <svg-icon-qouting
            :data='[[{ "col": 1, "row": 1, "image": sectionsData[0].icon, "width": 1, "height": 1, "number": "1" }]]'
            :key="`section-example-${index}`" />
        </div>

      </div>

      <div class="enter-size__right- tw-flex-1">

        <div v-if="sizeData.mode === 'calc'">
          <div class="md:tw-max-w-md">
            <div class="enter-size__overall">
              <InputGroup class="overall__input width"
                :class="{ 'order-1': firstDimension == 'width', 'order-2': firstDimension == 'drop' }"
                :data="overall_inputs.overall_width" :filter="numberFilter" v-model="overall_inputs.overall_width.value"
                @handletab="($event) => firstDimension == 'width' ? focusDrop($event) : null">
                <!--                        @update:modelValue="applyLayoutFormula"-->
                <template #label>
                  <HelpTopic v-if="allData.current_step.help_topic_url_overall_width"
                    :iframe-data="allData.current_step.help_topic_url_overall_width" />
                </template>
              </InputGroup>
              <InputGroup class="overall__input drop"
                :class="{ 'order-2': firstDimension == 'width', 'order-1': firstDimension == 'drop' }"
                :data="overall_inputs.overall_drop" :filter="numberFilter" v-model="overall_inputs.overall_drop.value"
                @handletab="($event) => firstDimension !== 'width' ? focusWidth($event) : null">
                <template #label>
                  <HelpTopic v-if="allData.current_step.help_topic_url_overall_drop"
                    :iframe-data="allData.current_step.help_topic_url_overall_drop" />
                </template>
              </InputGroup>
            </div>
            <template v-if="showRows">
              <div class="tw-border tw-border-solid tw-mt-2.5"></div>
              <h5 class="tw-mt-2.5">{{ isQuoting ? 'Panel Size:' : 'Manufacture Size:'}} </h5>
              <div class="tw-grid tw-mt-3 tw-w-full tw-gap-2" v-for="(item, index) in sectionsData"
                :key="`sizes-row-${index}`">
                <div class="tw-items-center tw-grid tw-gap-2"
                  :class="{ 'order-1': firstDimension == 'width', 'order-2': firstDimension == 'drop' }">

                  <div class="tw-flex tw-gap-10 tw-justify-between tw-items-center">
                      <div class="tw-w-full">{{ item.id }}. Width (mm)</div>
                      <InputGroup class="tw-w-full"
                        :data="{type: 'number', disabled: true}" v-model="item.manufacturing_sizes.width">
                      </InputGroup>
                  </div>

                  <div class="tw-my-2--" v-if="!isQuoting">
                    <FormulaInputGroup class="layout-settings__item" :data="{
                      label: '',
                      required: true,
                      error: '',
                      value: 'width',
                      disabled: isInputDisabled('width', item)
                    }" :item="item"
                      :placeholders="['overall width', 'total number of panels', 'number of opening panels']"
                      v-model="item.layout_formula.width" @update:modelValue="changePanelFormula('width', item)" />
                  </div>
                </div>

                <div class="tw-items-center tw-grid tw-gap-2"
                  :class="{ 'order-2': firstDimension == 'width', 'order-1': firstDimension == 'drop' }">

                  <div class="tw-flex tw-gap-10 tw-justify-between tw-items-center">
                      <div class="tw-w-full">{{ item.id }}. Drop (mm)</div>
                      <InputGroup class="tw-w-full"
                        :data="{type: 'number', disabled: true}" v-model="item.manufacturing_sizes.drop">
                      </InputGroup>
                  </div>

                  <div class="" v-if="!isQuoting">
                    <FormulaInputGroup class="layout-settings__item" :data="{
                      label: '',
                      required: true,
                      error: '',
                      value: 'drop',
                      disabled: isInputDisabled('drop', item)
                    }" :item="item"
                      :placeholders="['overall drop', 'total number of panels', 'number of opening panels']"
                      v-model="item.layout_formula.drop" @update:modelValue="changePanelFormula('drop', item)" />
                  </div>
                </div>

              </div>
            </template>
          </div>
          <div class="tw-my-4 tw--ml-3">
            <RadioGroupItems v-if="showRows && !isQuoting" class="enter-size__panel deduction_radios" :items="deductionRadioButtons"
              v-model="sizeData.selected_deduction_action" />
          </div>
          <div class="tw-max-w-sm">
            <InputGroup v-if="sizeData.selected_deduction_action === 'save'" class="overall__input" :data="{
              label: 'New deduction set name',
              placeholder: 'Enter new deductions set name',
              disabled: false,
              type: 'text',
              required: true,
              error: '',
            }" v-model="sizeData.new_deduction_name">
            </InputGroup>
          </div>

        </div>
        <div v-else class="md:tw-max-w-md">
          <div class="enter-size__overall" v-if="layoutData">
            <InputGroup class="overall__input width"
              :class="{ 'order-1': firstDimension == 'width', 'order-2': firstDimension == 'drop' }"
              :data="overall_inputs.overall_width" :filter="numberFilter" v-model="overall_inputs.overall_width.value"
              @handletab="($event) => firstDimension == 'width' ? focusDrop($event) : null"
              >
              <!--                        @update:modelValue="applyLayoutFormula"-->
              <template #label>
                <HelpTopic v-if="allData.current_step.help_topic_url_overall_width"
                  :iframe-data="allData.current_step.help_topic_url_overall_width" />
              </template>
            </InputGroup>
            <InputGroup class="overall__input drop"
              :class="{ 'order-2': firstDimension == 'width', 'order-1': firstDimension == 'drop' }"
              :data="overall_inputs.overall_drop" :filter="numberFilter" v-model="overall_inputs.overall_drop.value"
              @handletab="($event) => firstDimension !== 'width' ? focusWidth($event) : null"
              >
              <template #label>
                <HelpTopic v-if="allData.current_step.help_topic_url_overall_drop"
                  :iframe-data="allData.current_step.help_topic_url_overall_drop" />
              </template>
            </InputGroup>
          </div>

          <div class="tw-border tw-border-solid tw-mt-2.5"></div>
          <h5 class="tw-mt-2.5">Manufacture Size:</h5>

          <div class="tw-grid tw-gap-2 tw-mt-3 tw-w-full"
            v-for="(item, index) in sectionsData" :key="`sizes-row-${index}`">
            <div class=""
              :class="{ 'order-1': firstDimension == 'width', 'order-2': firstDimension == 'drop' }">
                <div class="tw-flex tw-gap-10 tw-justify-between tw-items-center">
                      <div class="tw-w-full">{{ item.id }}. Width (mm)</div>
                      <InputGroup class="tw-w-full"
                        :data="{type: 'number', min: 0, disabled: isInputDisabled('width', item), tabindex: firstDimension == 'width'? 1+index: 2+index}" v-model="item.manufacturing_sizes.width" @update:modelValue="changePanelMakeSize('width', item)">
                      </InputGroup>
                </div>
            </div>
            <div class=""
              :class="{ 'order-2': firstDimension == 'width', 'order-1': firstDimension == 'drop' }">
                <div class="tw-flex tw-gap-10 tw-justify-between tw-items-center">
                    <div class="tw-w-full">{{ item.id }}. Drop (mm)</div>
                    <InputGroup class="tw-w-full"
                      :data="{type: 'number', min: 0, disabled: isInputDisabled('drop', item), tabindex: firstDimension != 'width'? 1+index: 2+index}" v-model="item.manufacturing_sizes.drop" @update:modelValue="changePanelMakeSize('drop', item)">
                    </InputGroup>
                </div>
            </div>
          </div>

        </div>

        <div class="validation_text" v-show="validationMinMax">*{{ validationMinMax }}</div>
      </div>

    </div>
    <ConfirmationModal
      message="You have not entered Overall Sizes. Overall Sizes can assist with ordering accessories and additions, etc.<br>
Do you wish to proceed?
"
      :cancel_btn_data="{ type: 'primary', title: 'No', icon: 'fa fa-times'  }"
      :confirm_btn_data="{ type: 'primary', title: 'Yes', icon: 'fa fa-check' }"
      @confirmClicked="submit"
      v-model:show="showOverallSizesAlert"
    />  </div>
</template>

<script>
import _ from 'lodash';
import { reactive, ref, computed, watch } from 'vue'
import { isOpeningIsThePreferToEnter, isDropIsTheSizeToEnter, firstDimensionPreference, sizeEnteringPreference } from '../../helpers/portalSpecifications.js'
import { numberFilter } from '../../helpers/objects'
import { Parser } from "expr-eval";

import HelpTopic from '../elements/HelpTopic.vue'
import RadioGroupItems from '../form/RadioGroupItems'
import InputGroup from '../form/InputGroup'
import Line from '../elements/Line.vue'
import SelectDropdown from '../elements/SelectDropdown.vue'
import InputSize from '../form/InputSize'
import FormulaInputGroup from '../form/FormulaInputGroup'
import { numeric } from "@vee-validate/rules";
import { clearValidationError, checkValidation } from '../../helpers/objects'
import ConfirmationModal from "../modals/ConfirmationModal.vue";


// import PanelOpeningInputSize from '../form/PanelOpeningInputSize'
// import InformationConfirmationModal from "../modals/InformationConfirmationModal";
// import FormulaSelection from '../form/FormulaSelection.vue'
// import Checkbox from '../form/Checkbox.vue'

export default ({
  components: {
    HelpTopic,
    RadioGroupItems,
    InputGroup,
    Line,
    SelectDropdown,
    InputSize,
    FormulaInputGroup,
    ConfirmationModal
    // PanelOpeningInputSize,
    // InformationConfirmationModal,
    // FormulaSelection,
    // Checkbox,
  },
  props: ['items', 'allData', 'isQuoting'],
  setup(props, { emit }) {
    //Variables
    const sizeData = ref(null)
    const showOverallSize = ref(false)
    const showRows = ref(false)
    const firstDimension = ref(props.isQuoting ? 'width' : firstDimensionPreference())
    const showLayout = ref(false)
    const sectionsData = ref([])
    const layoutData = ref(null)
    const isCustomLayout = ref(false)
    const needValidation = ref(false)
    const showOverallSizesAlert = ref(false)

    const showLayoutIcon = computed(() => {
      const layout = props.allData.steps.find(x => x.name === 'Layout')
      return !(layout?.data?.is_custom)
    })

    const availableDeductionSetsForDropDown = ref(props.items.data.deductions ? props.items.data.deductions.concat({
      id: 0,
      settings: [],
      name: 'Layout Default'
    }) : [{ id: 0, settings: [], name: 'Layout Default' }])
    const selectedSet = ref(null)

    const modes = reactive([
      {
        value: 'calc', label: 'Layout size', key: 'calc'//, hidden: !props.isQuoting
      },
      {
        value: 'open', label: 'Panel open sizes', key: 'open', hidden: !props.isQuoting
      },
      {
        value: 'make', label: 'Make sizes', key: 'make', hidden: props.isQuoting
      },
    ])

    const isAllSectionsSameSizeOptions = [
      {
        value: true, label: 'Same size', key: 'all_sections_same_size'
      },
      {
        value: false, label: 'Different', key: 'all_sections_same_size'
      }
    ]

    const overall_inputs = reactive({
      overall_width: {
        label: 'Overall width',
        suffix: '(mm)',
        placeholder: 'Your value',
        disabled: false,
        type: 'number',
        required: true,
        error: '',
        min: '0',
        tabindex: firstDimension.value === 'width' ? -1 : 1,
        value: props.items.data.overall_width
      },
      overall_drop: {
        label: 'Overall drop',
        suffix: '(mm)',
        placeholder: 'Your value',
        disabled: false,
        type: 'number',
        required: true,
        error: '',
        min: '0',
        tabindex: firstDimension.value !== 'width' ? -1 : 1,
        value: props.items.data.overall_drop
      },
    })

    const focusDrop = (e) => {
      e.preventDefault();
      document.querySelector('.overall__input.drop input').focus()
    }

    const focusWidth = (e) => {
      e.preventDefault();
      document.querySelector('.overall__input.width input').focus()
    }

    const deductionRadioButtons = reactive([
      {
        value: 'override', label: 'Override deductions', key: 'override', disabled: false
      },
      {
        value: 'save', label: 'Save deductions as new', key: 'save', disabled: false
      },
      {
        value: 'do-not-save', label: 'Do not save deductions', key: 'do-no-save', disabled: false
      }
    ])

    const showSameDifferentSize = computed(() => {
      return !props.isQuoting && sectionsData.value && sectionsData.value.length > 1
    })

    const validationMinMax = computed(() => {
      if (!needValidation.value) return
      const {
        min_drop,
        min_width,
        max_drop,
        max_width,
        overall_drop,
        overall_width
      } = props.items.data

      let error = ''
      const rows = []
      const cols = []

      //get rows and cols
      // sectionsData.value.forEach(el => {
      //   if (!rows.includes(el.row)) rows.push(el.row)
      //   if (!cols.includes(el.col)) cols.push(el.col)
      // })
      let { width, drop } = getLayoutWidthAndDrop()
      let dropSum = width
      let widthSum = drop
      //sum first item in rows/cols
      // rows.forEach(row => {
      //   const firstItemInRow = sectionsData.value.find(el => el.row == row)
      //   dropSum += parseFloat(firstItemInRow.drop)
      // })
      // cols.forEach(col => {
      //   const firstItemInCol = sectionsData.value.find(el => el.col == col)
      //   widthSum += parseFloat(firstItemInCol.width)
      // })

      //min & max errors
      let widthMinMaxError = false
      let dropMinMaxError = false

      sectionsData.value.forEach(el => {
        const elementDrop = el.manufacturing_sizes.drop
        const elementWidth = el.manufacturing_sizes.width

        let error = false

        if (elementDrop < min_drop || elementDrop > max_drop) {
          dropMinMaxError = true
          error = true
        }
        if (elementWidth < min_width || elementWidth > max_width) {
          widthMinMaxError = true
          error = true
        }

        if (error && el.error) return
        else el.error = error
      })


      //set error text

      // if (overall_drop < dropSum) error = 'sum of drops for different rows cannot exceed the overall drop'
      // if (overall_width < widthSum) error = 'sum of widths for different columns cannot exceed overall width'
      if (widthMinMaxError) error = `all panels' widths must be between ${min_width} and ${max_width} mm`
      if (dropMinMaxError) error = `all panels' drops must be between ${min_drop} and ${max_drop} mm`

      /*  if(error === ''){
          if(parseFloat(overall_width) < min_width || parseFloat(overall_width) > max_width) error = `overall width must be between ${min_width} and ${max_width} mm`
          if(parseFloat(overall_drop) < min_drop || parseFloat(overall_drop) > max_drop) error = `overall drop must be between ${min_drop} and ${max_drop} mm`
        }*/

      return error
    })

    initialise()

    watch(() => sizeData.value.mode, (value) => {

      showOverallSize.value = value === 'calc'
      if (value === 'calc') {
        updatePanelSizes()
      }
    })

    watch(() => sizeData.value.is_all_sections_same_size, (value) => {
      if (value === true && sizeData.value.mode !== 'make') {
        changePanelFormula('width', sectionsData.value[0])
        changePanelFormula('drop', sectionsData.value[0])
      } else if (value === true && sizeData.value.mode === 'make') {
        changePanelMakeSize('width', sectionsData.value[0])
        changePanelMakeSize('drop', sectionsData.value[0])
      }
    })

    watch(() => overall_inputs.overall_width.value, (value) => {
      overallChanges()
    })
    watch(() => overall_inputs.overall_drop.value, (value) => {
      overallChanges()
    })

    //Methods
    async function initialise() {
      sizeData.value = {
        mode: props.items.data.mode ?? (props.isQuoting ? 'open' : 'calc'),
        is_all_sections_same_size: !!props.items.data.is_all_sections_same_size,
      }

      getSectionsData()
      overallChanges()

      if (props.items.data.deduction_set) {
        selectedSet.value = _.find(availableDeductionSetsForDropDown.value, { id: props.items.data.deduction_set })
      }
      else if (_.find(availableDeductionSetsForDropDown.value, { is_default: 1 })) {
        selectedSet.value = _.find(availableDeductionSetsForDropDown.value, { is_default: 1 })
      } else {
        selectedSet.value = _.find(availableDeductionSetsForDropDown.value, { id: 0 })
      }
      sizeData.value.selected_deduction_action = 'do-not-save'
      updateRadioButtonLogics()
    }

    function getSectionsExampleClass() {
      if (layoutData.value) {
        if (layoutData.value[0].length > 7) return 'big'
        if (layoutData.value[0].length > 5) return 'medium'
      }
      return 'medium'
    }

    function getSectionsData() {
      const data = JSON.parse(JSON.stringify(props.items.data.sections))

      //data for work
      sectionsData.value = data.map((el, index) => {
        el.layout_formula = el.deduction_formula ?? {
          width: '',
          drop: ''
        }
        return {
          error: false,
          disabled: {
            drop: false,
            width: false,
          },
          manufacturing_sizes: {
            width: el.width,
            drop: el.drop
          },
          deduction_formula: {
            width: '',
            drop: ''
          },
          ...el,
        }
      })
      //sort by id
      sectionsData.value.sort((a, b) => a.id - b.id)

      //get section example
      const layoutStepName = 'Layout'
      const layoutStep = props.allData.steps.find(el => el.name == layoutStepName)

      if (!layoutStep || (layoutStep.data.is_make_size_only))
        checkSizeMode(false)

      if (layoutStep) {
        if (!layoutStep.data.is_make_size_only || layoutStep.data.is_custom)
          layoutData.value = layoutStep.data.section_settings

        isCustomLayout.value = layoutStep.data.is_custom

        if (isCustomLayout.value)
          sizeData.value.is_all_sections_same_size = false || layoutStep.data.is_make_size_only

        if (layoutData.value) {
          sectionsData.value = sectionsData.value.map((s) => {
            if (!s.layout_formula.width)
              s.layout_formula.width = layoutData.value[s.row - 1][s.col - 1]?.default_formula_width
            if (!s.layout_formula.drop)
              s.layout_formula.drop = layoutData.value[s.row - 1][s.col - 1]?.default_formula_drop
            return s
          })
        }
      }
      // updOpeningPanels()
    }

    function checkSizeMode(val) {
      if (!val) {
        sizeData.value.mode = 'make'
      }
      modes.find(i => i.key == 'calc').disabled = !val
    }

    function overallChanges() {
      showRows.value = (overall_inputs.overall_width.value ?? 0 > 100) &&
        (overall_inputs.overall_drop.value ?? 0 > 100)

      if (showRows.value || props.isQuoting)
        updatePanelSizes()
    }

    function changePanelFormula(type, section) {
      sectionsData.value = sectionsData.value.map((s) => {
        if (sizeData.value.is_all_sections_same_size) {
          if (s.id != section.id) {
            s.layout_formula[type] = section.layout_formula[type]
            updatePanelSize(s)
          }
        } else {
          if (type == 'width' && s.col == section.col && s.row > section.row) {
            s.layout_formula.width = section.layout_formula.width
            updatePanelSize(s)
          }
          if (type == 'drop' && s.row == section.row && s.col !== section.col) {
            s.layout_formula.drop = section.layout_formula.drop
            updatePanelSize(s)
          }
        }
        return s
      })
      updatePanelSize(section)
    }

    function changePanelMakeSize(type, section) {
      sectionsData.value = sectionsData.value.map((s) => {
        if (sizeData.value.is_all_sections_same_size) {
          if (s.id != section.id) {
            s.manufacturing_sizes[type] = section.manufacturing_sizes[type]
          }
        } else {
          if (type == 'width' && s.col == section.col && s.row > section.row) {
            s.manufacturing_sizes.width = section.manufacturing_sizes.width
          }
          if (type == 'drop' && s.row == section.row && s.col !== section.col) {
            s.manufacturing_sizes.drop = section.manufacturing_sizes.drop
          }
        }
        return s
      })
    }

    function updatePanelSize(section) {
      if (sizeData.value.mode == 'make')
        return

      const placeholders = {
        '{overall width}': overall_inputs.overall_width.value,
        '{overall drop}': overall_inputs.overall_drop.value,
        '{total number of panels}': props.items.data.sections.length,
        '{number of opening panels}': props.items.data.sections.length
      }
      let formula_w = section.layout_formula.width
      let formula_d = section.layout_formula.drop
      _.forEach(placeholders, (value, key) => {
        formula_w = formula_w.replaceAll(key, value)
        formula_d = formula_d.replaceAll(key, value)
      })

      const parser = new Parser()

      if (formula_w == '' || formula_w == null || formula_d == '' || formula_d == null) {
        return true
      }
      try {
        section.manufacturing_sizes.width = parser.evaluate(formula_w).toFixed(1)
        section.manufacturing_sizes.drop = parser.evaluate(formula_d).toFixed(1)
      } catch (ex) {
        return false
      }

      if (section.manufacturing_sizes.width && section.manufacturing_sizes.width <= 0) section.manufacturing_sizes.width = null
      if (section.manufacturing_sizes.drop && section.manufacturing_sizes.drop <= 0) section.manufacturing_sizes.drop = null

      sectionsData.value = sectionsData.value.map((el) => {
        if (el.id == section.id)
          return section
        return el
      })

    }

    function updatePanelSizes() {
      sectionsData.value.forEach((section) => {
        updatePanelSize(section)
      });
    }

    function isInputDisabled(type, section) {
      const layoutStepName = 'Layout'
      const layoutStep = props.allData.steps.find(el => el.name == layoutStepName)

      if (layoutStep?.data?.is_make_size_only) return false

      if (sizeData.value.is_all_sections_same_size) {
        return section.id != 1
      } else {
        if (type === 'width') {
          let minRow = 999
          layoutData.value?.map(row => {
            row.map(col => {
              if (col.col == section.col && numeric(col.number)) {
                minRow = Math.min(minRow, col.row)
              }
            })
          })
          return section.row != minRow
        } else if (type === 'drop') {
          let minCol = 999
          layoutData.value?.map(row => {
            row.map(col => {
              if (col.row == section.row && numeric(col.number)) {
                minCol = Math.min(minCol, col.number)
              }
            })
          })
          return section.id != minCol
        }
      }
    }

    function applyLayoutFormula(config) {
      sectionsData.value.forEach(s => {

        const setting = config.settings.find(x => x.id == s.id)
        if (setting) {
          s.layout_formula.width = setting.width
          changePanelFormula('width', s)

          s.layout_formula.drop = setting.drop
          changePanelFormula('drop', s)
        }
      })
      selectedSet.value = config
      updateRadioButtonLogics()
    }

    function updateRadioButtonLogics() {
      if (sizeData.value.selected_deduction_action === 'override' && selectedSet.value?.id == 0) {
        sizeData.value.selected_deduction_action = 'do-not-save'
      }
      //When deductions set = 'Not selected', 'Override' radio button should be disabled and 'Do not save' selected as default
      if (!sizeData.value.selected_deduction_action) {
        sizeData.value.selected_deduction_action = 'do-not-save'
      }
      //When 'selected deduction set' ➝ dealer_id = null, 'Override' radio button should be disabled and 'Do not save' selected as default
      _.find(deductionRadioButtons, { value: 'override' }).disabled = (selectedSet.value?.id ?? 0) == 0

    }

    function submit() {
      //if one layout
      if (!layoutData.value) {
        const firstPanel = sectionsData.value[0]
        props.items.data.overall_width = firstPanel.width
        props.items.data.overall_drop = firstPanel.drop
      } else {
        props.items.data.overall_width = overall_inputs.overall_width.value
        props.items.data.overall_drop = overall_inputs.overall_drop.value
      }
      //check validation
      if (validation()) return

      if (sizeData.value.mode == 'make') {
        selectedSet.value = _.find(availableDeductionSetsForDropDown.value, { id: 0 })
        sizeData.value.selected_deduction_action = 'do-not-save'

        const layoutStepName = 'Layout'
        const layoutStep = props.allData.steps.find(el => el.name == layoutStepName)

        if (layoutStep &&
          !layoutStep.data.is_make_size_only &&
          !showOverallSizesAlert.value
          && (!overall_inputs.overall_width.value || !overall_inputs.overall_drop.value)){
          showOverallSizesAlert.value = true
          return
        }

      }

      delete props.items.data['deduction_set']
      if (selectedSet.value?.id ?? 0 != 0)
        props.items.data.selected_deduction_set = selectedSet.value

      props.items.data.mode = sizeData.value.mode
      props.items.data.is_all_sections_same_size = sizeData.value.is_all_sections_same_size
      props.items.data.selected_deduction_action = sizeData.value.selected_deduction_action

      if (props.items.data.selected_deduction_action === 'save') {
        props.items.data.new_deduction_name = sizeData.value.new_deduction_name
      }

      //set panel size to payload
      props.items.data.sections.forEach((el, index) => {
        const data = sectionsData.value.find(x => x.id === el.id)

        el.drop = data.manufacturing_sizes.drop
        el.width = data.manufacturing_sizes.width
        el.manufacturing_sizes = data.manufacturing_sizes,
          el.opening_sizes = { width: el.width, drop: el.drop }

        el.deduction_formula = sizeData.value.mode !== 'make' ? data.layout_formula : { width: null, drop: null }
      })

      if (props.items.data.deductions) delete props.items.data['deductions'];
      //submit
      emit('submit', props.items.data)
    }

    function validation() {
      needValidation.value = true
      let error = false

      if (overall_inputs.overall_drop.error || overall_inputs.overall_width.error) {
        error = true
      }

      if (checkPanelSizeValidation() || validationMinMax.value) error = true

      return error
    }

    function checkPanelSizeValidation() {
      let error = false
      sectionsData.value.forEach(el => {
        if (!el.manufacturing_sizes.drop || !el.manufacturing_sizes.width) {
          if (!error) error = true
          el.error = true
        }
      })
      return error
    }

    function getLayoutWidthAndDrop(size_type) {
      const rows = []
      const cols = []

      //get rows and cols
      sectionsData.value.forEach(el => {
        if (!rows.includes(el.row)) rows.push(el.row)
        if (!cols.includes(el.col)) cols.push(el.col)
      })
      let dropSum = 0
      let widthSum = 0
      //sum first item in rows/cols
      rows.forEach(row => {
        const firstItemInRow = sectionsData.value.find(el => el.row == row)
        if (size_type == 'open')
          dropSum += parseFloat(firstItemInRow.drop)
        else
          dropSum += parseFloat(firstItemInRow.manufacturing_sizes.drop)
      })
      cols.forEach(col => {
        const firstItemInCol = sectionsData.value.find(el => el.col == col)
        if (size_type == 'open')
          widthSum += parseFloat(firstItemInCol.width)
        else
          widthSum += parseFloat(firstItemInCol.manufacturing_sizes.width)
      })
      return { width: widthSum, drop: dropSum }
    }



    return {
      showLayoutIcon,
      modes,
      overall_inputs,
      isAllSectionsSameSizeOptions,
      deductionRadioButtons,
      availableDeductionSetsForDropDown,
      selectedSet,

      sizeData,
      firstDimension,
      showOverallSize,
      showSameDifferentSize,
      showLayout,
      showRows,

      sectionsData,
      layoutData,
      validationMinMax,


      numberFilter,
      getSectionsExampleClass,
      changePanelFormula,
      isInputDisabled,
      changePanelMakeSize,
      applyLayoutFormula,
      submit,
      focusDrop,
      focusWidth,
      showOverallSizesAlert
    }

  },
})
</script>


<style scoped lang="scss">
::v-deep .make-size-checkbox {
  .form-group label[data-v-6d05e0e2]:before {
    margin-right: 4px;
  }
}

//::v-deep .input-size__prefix {
//  width: 48px;
//}

::v-deep .input-size.formula {
  margin-left: 0;
  margin-right: 0;

  .input-size__input {
    width: 125px;
  }
}

.validation_text {
  right: 0;
  left: auto;
  margin: 20px;
  position: relative;

  @media (min-width: 768px) {
    position: absolute;
  }
}

.deduction_radios {
  margin: 10px;
}

.enter-size {
  position: relative;
  height: calc(100% - 115px);

  &__container {
    display: flex;
    //height: 100%;
    height: auto;
    padding-bottom: 40px;
    //overflow-y: hidden;

  }

  &__mode {
    width: 100%;
  }

  &__left {
    margin-right: 43px;
  }

  &__right {

    //padding-bottom: 40px;
    //overflow-y: scroll;
    @media (max-width: 768px) {
      margin-top: 0 !important;
    }

    width : auto;

    &__container {
      display: flex;
      flex-direction: row;

      &__item {
        flex-grow: 1;
        margin: 6px;
      }
    }
  }

  &__overall {
    display: flex;
    gap: 2.5rem;

    .overall {
      &__input {
        // width: 170px;
        // margin-right: 24px;
      }
    }
  }

  &__panel {
    display: flex;

    .panel {
      &__item {
        width: 100px;
        margin-right: 37px;
      }
    }
  }

  &__title {
    font-size: 15px;
    line-height: 140%;
    margin: 9px 0;
    text-align: left;
  }

  &__example {
    display: flex;
    justify-content: flex-start;
    margin-top: 60px;
    width: fit-content;
    position: relative;

    .example {
      &__line {

        &_width,
        &_height {
          position: absolute;
        }

        &_width {
          top: -20px;
          // width: 100%;
        }

        &_height {
          right: -20px;
          // height: 100%;
        }
      }
    }

    // ::v-deep svg{
    //     font-size: 150px;
    // }
  }

  @media(max-width: 768px) {
    &__overall {
      @apply tw-grid tw-grid-cols-2;

      flex-wrap: inherit;

      .overall__input {
        margin-bottom: 10px;
        width: 100%;
        margin: 0;
      }

      padding-left: 3px;
    }

    // &__left {
    //   margin-right: 0;
    // }

    &__container {
      flex-direction: column;
    }

    // &__right {
    //   margin-top: 20px;
    // }
  }

}

:deep(.icons__wrapper) {
  // max-height: 150px;
  display: flex;
}

:deep(.svg-inline) {
  width: 100px;

  &.width_0_5 {
    height: 200px;
  }

  &.width_2 {
    width: 200px;
  }
}

.big {
  :deep(.svg-inline) {
    width: 60px;

    &.width_0_5 {
      height: 120px;
    }

    &.width_2 {
      width: 120px;
    }
  }
}

.medium {
  :deep(.svg-inline) {
    width: 80px;

    &.width_0_5 {
      height: 160px;
      width: auto;
    }

    &.width_2 {
      width: 160px;
    }
  }
}

.sizes {
  &__title {
    @media (max-width: 768px) {
      margin-bottom: 5px;
    }

    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    text-align: left;
  }

  &__row {
    @media (max-width: 768px) {
      flex-wrap: nowrap !important;
      margin-top: 6px !important;
      align-items: baseline !important;
      font-size: 18px !important;
    }

    font-size: 24px;
    line-height: 27px;
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  &__item {
    @media (max-width: 768px) {
      margin: 0 10px !important;
    }

    margin-right: 17px;
    margin-left: 11px;
  }

  @media(max-width: 768px) {
    &__row {
      flex-wrap: wrap;
    }
  }
}

.change_flex_direction {
  flex-direction: row-reverse;
}

.change_order {
  order: 1;
}

.free_text {
  border: none;
  border-bottom: 1px solid #000000;
  border-radius: 2px;
  height: 40px;
  width: 50%;
  padding: 0 13px 0 15px;
  margin-left: 10px;
  background-color: transparent;

}

.select_deduction {
  width: 50%;
  padding: 0.3rem;
  font-size: 1rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 2px solid #0B3553;
  background-color: #ffffff;
  margin-bottom: 10px;

}

.make-size-note {
  font-size: 0.8rem;
  width: 100%;
  text-align: center;
  padding-right: 80px;
  font-family: "Lato", Helvetica, "Roboto", Arial, sans-serif;
}
</style>
